import { MenuItem, Pagination, Select, ThemeProvider } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import theme from "../config/theme";
import {
  getCategoryPodcasts,
  getLatestPodcasts,
} from "../redux/actions/podcastActions";
import { OPEN_AUTH_MODAL, SET_CURRENT_PODCAST } from "../redux/types";
import Loader from "./Loader";
import Podcast from "./Podcast";

const Content = () => {
  const { state } = useLocation();
  const [categoryId, setCategoryId] = useState();
  const { categories, loading: loadCategories } = useSelector(
    (state) => state.categoriesList
  );

  const { user } = useSelector((state) => state.loginUser);

  const handlePlay = (podcast) => {
    if (!user) return dispatch({ type: OPEN_AUTH_MODAL });
    dispatch({ type: SET_CURRENT_PODCAST, payload: podcast });
  };

  const { podcasts, loading, pages } = useSelector(
    (state) => state.categoryPodcasts
  );
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const handlePageChange = (event, value) => {
    setPage(value);
  };
  useEffect(() => {
    dispatch(getCategoryPodcasts(categoryId || state.categoryId));
  }, [categoryId, dispatch, page]);
  return (
    <main className=" flex flex-col items-center mt-5">
      <div className="w-[90%] sm:w-[80%]">
        {!loadCategories && (
          <div>
            <Select
              value={categoryId}
              defaultValue={state.categoryId}
              placeholder="name"
              MenuProps={{
                classes: {
                  paper: "!bg-[#1f1f1f] border !border-white",
                },
                style: { marginTop: "1rem", backgroundColor: "transparent" },
              }}
              className="border border-white bg-transparent child:!bg-transparent child:!border-none child:!text-white"
              onChange={(e) => setCategoryId(e.target.value)}>
              {categories?.map((category) => (
                <MenuItem
                  className=" !text-white hover:!bg-[#6AC045]"
                  key={category.id}
                  value={category.id}>
                  {category.name}
                </MenuItem>
              ))}
            </Select>
          </div>
        )}

        {loading ? (
          <Loader />
        ) : (
          <div className="flex flex-wrap gap-4 my-6">
            {podcasts?.map((x) => (
              <NavLink
                to={`/watch/${x.id}`}
                state={{ categoryId: x?.category_id }}
                className={({ isActive }) =>
                  isActive
                    ? "rounded-md bg-[#6AC045] p-2 px-4 text-white"
                    : "rounded-md bg-[#393939] p-2 px-4 text-white"
                }>
                {x.name}
              </NavLink>
            ))}
          </div>
        )}

        <ThemeProvider theme={theme}>
          <div className="col-span-12 grid place-items-center my-6">
            {pages > 1 && (
              <Pagination
                color="secondary"
                className="!border-red-400 !text-red-500 !bg-red-400"
                count={pages}
                page={page}
                onChange={handlePageChange}
                shape="rounded"
                variant="outlined"
              />
            )}
          </div>
        </ThemeProvider>
      </div>
    </main>
  );
};

export default Content;
