import { CircularProgress } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

const LoadingButton = ({ children, className, ...other }) => {
  const { loading } = useSelector((state) => state.subscribeUser);
  return (
    <button
      {...other}
      className={`!bg-[#FFCB00] rounded-sm p-2 px-3 !text-black font-bold  flex items-center justify-center ${
        loading && ""
      } ${className}`}
      disabled={loading}>
      {loading ? (
        <CircularProgress size={20} className="!text-black" />
      ) : (
        children
      )}
    </button>
  );
};

export default LoadingButton;
