import { Close, Login, Menu, Search } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { logout, subscribe } from "../redux/actions/authActions";

import { getCategories } from "../redux/actions/categoryActions";
import { getPodcasts } from "../redux/actions/podcastActions";
import LoadingButton from "./LoadingButton";
import Searchbar from "./Searchbar";
import Sidebar from "./Sidebar";

const Header = ({ hide }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);

  const [keyword, setKeyword] = useState("");
  const slideRef = useRef(null);

  const handleClick = (e) => {
    setOpenSearch(!openSearch);
    setAnchorEl(e.currentTarget);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(getPodcasts(keyword));
  };
  const { user } = useSelector((state) => state.loginUser);

  const { categories } = useSelector((state) => state.categoriesList);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);
  return (
    <>
      <header className="flex flex-col w-full gap-6 sticky items-center justify-between z-10 top-0  bg-black text-white p-6 px-[2rem] md:px-[5rem]  xl:px-[12rem] shadow">
        <div className="flex items-center justify-between gap-x-6 w-full">
          <div className="flex items-center">
            <IconButton className="!text-white" onClick={(e) => setOpen(true)}>
              <Menu />
            </IconButton>
            <Link
              className="ml-6 w-24 flex  items-center justify-center gap-x-2 "
              to="/">
              <img src="/mtn-logo.jpg" className="h-10 rounded block" />
              <div className="bg-purple-400 rounded mr-2">
                <img src="/laha_m.png" className="h-10 rounded-full block" />
              </div>
            </Link>
          </div>
          <form
            className="bg-transparent text-black
             w-full hidden md:flex justify-center"
            // onSubmit={handleSearch}
          >
            <input
              type="search"
              // value={keyword}
              className="rounded bg-gray-700 p-2 outline-none w-[80%] rounded-tl-none rounded-bl-none"
              // onChange={(e) => setKeyword(e.target.value)}
              placeholder="تصفحي لها"
            />
            <button className="bg-gray-800 text-white rounded rounded-tr-none rounded-br-none p-2 px-6">
              <Search />
            </button>
          </form>

          {!user ? (
            <LoadingButton
              variant="contained"
              onClick={() => dispatch(subscribe())}
              className="!bg-[#FFCB00]  !text-black font-bold">
              <a href="http://lahasd.com/index.php">اشتراك</a>
            </LoadingButton>
          ) : (
            <button
              onClick={dispatch(logout())}
              className="hidden md:block text-red-500">
              تسجيل الخروج
            </button>
          )}
        </div>

        <form
          className="bg-transparent text-black
             w-full flex md:hidden justify-center"
          onSubmit={handleSearch}>
          <input
            type="search"
            value={keyword}
            className="rounded bg-gray-700 p-2 outline-none w-[80%] text-white rounded-tl-none rounded-bl-none"
            onChange={(e) => setKeyword(e.target.value)}
            placeholder="تصفحي لها"
          />
          <button className="bg-gray-800 text-white rounded rounded-tr-none rounded-br-none p-2 px-6">
            <Search />
          </button>
        </form>
      </header>
      <Sidebar open={open} setOpen={setOpen} />
    </>
  );
};

export default Header;
