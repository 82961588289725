import { BrowserRouter, Route, Routes } from "react-router-dom";

import Home from "./pages/Home";
import { create } from "jss";
import rtl from "jss-rtl";
import { StylesProvider, jssPreset } from "@mui/styles";

import CategoryPage from "./pages/CategoryPage";
import Explore from "./pages/Explore";

import Login from "./pages/Login";
import Subscribe from "./pages/Subscribe";
import { useEffect } from "react";

// Configure JSS
const jss = create({
  plugins: [...jssPreset().plugins, rtl()],
});
// const loader = document.querySelector("#loader");
// const hideLoader = () => loader.classList.add("hidden");
function App() {
  useEffect(() => {
    // hideLoader();
  }, []);
  return (
    <div className="App bg-[#1f1f1f]">
      <StylesProvider jss={jss}>
        <BrowserRouter>
          <Routes>
            <Route index path="/" element={<Explore />} />
            <Route path="/categories/:category" element={<CategoryPage />} />
            <Route path="/watch/:id" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/subscribe" element={<Subscribe />} />
          </Routes>
        </BrowserRouter>
      </StylesProvider>
    </div>
  );
}

export default App;
