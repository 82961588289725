import { Search, SentimentDissatisfied } from "@mui/icons-material";
import { Pagination, Slide, ThemeProvider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import AudioPLayer from "../components/AudioPLayer";
import Header from "../components/Header";
import Loader from "../components/Loader";
import Podcast from "../components/Podcast";
import theme from "../config/theme";
import { getPodcasts } from "../redux/actions/podcastActions";
import { SET_CURRENT_PODCAST } from "../redux/types";

const Explore = () => {
  const [keyword, setKeyword] = useState("");

  const { podcasts, loading, pages } = useSelector(
    (state) => state.podcastsList
  );
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(getPodcasts(keyword));
  };
  useEffect(() => {
    dispatch(getPodcasts(keyword));
  }, [dispatch, page]);
  return (
    <div className="">
      <Header hide />

      <main className="min-h-screen mb-40">
        {loading ? (
          <Loader />
        ) : podcasts?.length === 0 ? (
          <div className="flex justify-center items-center min-h-screen !text-white">
            <SentimentDissatisfied className="!text-3xl ml-2" />
            <p className="text-3xl">لا يوجد نتائج</p>
          </div>
        ) : (
          <div className="grid grid-cols-12 justify-items-center gap-y-9 my-6">
            {" "}
            {podcasts?.map((x) => (
              <Podcast podcast={x} key={x.id} />
            ))}
          </div>
        )}
      </main>
      <ThemeProvider theme={theme}>
        <div className="col-span-12 grid place-items-center my-6">
          {pages > 1 && (
            <Pagination
              color="primary"
              count={pages}
              page={page}
              onChange={handlePageChange}
              shape="rounded"
              variant="outlined"
            />
          )}
        </div>
      </ThemeProvider>
      <div className="w-full h-1" />
      {/* <AudioPLayer /> */}
    </div>
  );
};

export default Explore;
