import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getPodcastDetails } from '../redux/actions/podcastActions'
import VideoPlayer from './VideoPlayer'


const LahaShow = () => {

    

  const {podcast, loading} = useSelector(state => state.podcastDetails)
  const {id} = useParams()
  const dispatch = useDispatch()
  useEffect(()=> {
   dispatch(getPodcastDetails(id))
  }, [id, dispatch])
  return (
    <div  className='mt-10'>
      
      <div className=' flex flex-col items-center justify-center '>
      <h2 className='text-gray-400 w-[90%]  sm:w-[80%] text-3xl mb-4'>{podcast?.name}</h2>
     
      {!loading &&<VideoPlayer url="/skin_types.mp4" podcast={podcast}/>}
      
     
      <div className=' flex  w-[90%]  sm:w-[80%] mt-4'>
      <p className='text-gray-400 w-[70%]  sm:w-[40%]  text-xl sm:text-justify'><strong className='text-white'>الوصف:</strong> {podcast?.description}</p>
      </div>

      </div>
    </div>
  )
}

export default LahaShow