import { Drawer, List, ListItem, ListItemText } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { logout } from "../redux/actions/authActions";

const Sidebar = ({ open, setOpen }) => {
  const { user } = useSelector((state) => state.loginUser);
  const dispatch = useDispatch();
  const { categories } = useSelector((state) => state.categoriesList);
  return (
    <Drawer
      open={open}
      anchor="right"
      classes={{ paper: "!bg-black text-white p-2 w-52 sm:w-64  md:w-72" }}
      onClose={() => setOpen(false)}
    >
      <List className="md:ml-4  ">
        <ListItem
          button
          className="w-full text-right !text-white !p-2 !pt-4 child:border-b child:border-slate-600 child:mx-4"
          component={Link}
          to="/"
        >
          الرئيسية
        </ListItem>
        {categories?.map((category) => (
          <ListItem
            button
            key={category.id}
            component={Link}
            className="w-full text-right !text-white !p-2 !pt-4 child:border-b child:border-slate-600 child:mx-4"
            to={`/categories/${category.id}`}
          >
            {category.name}
          </ListItem>
        ))}
        {user ? (
          <ListItem
            onClick={() => dispatch(logout())}
            className="text-white cursor-pointer"
          >
            <ListItemText
              primary="تسجيل الخروج"
              className="!p-2 !pt-4 border-b border-slate-600 text-right hover:!text-4xl"
            />
          </ListItem>
        ) : (
          <ListItem
            button
            to="/login"
            component={Link}
            className="w-full text-right !text-white !p-2 !pt-4 child:border-b child:border-slate-600 child:mx-4"
          >
            تسجيل الدخول
          </ListItem>
        )}
      </List>
    </Drawer>
  );
};

export default Sidebar;
