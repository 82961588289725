import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player/youtube";
import URL from "../redux/api";

const VideoPlayer = ({ podcast }) => {
  const [stream, setStream] = useState("");
  console.log({ stream });
  useEffect(() => {
    async function fetchVid() {
      try {
        const { data } = await axios.get(`${URL}/podcast/${podcast.id}/video`, {
          headers: {
            Authorization: "Bearer 5|Wq928qlpZIdMUECJ2xWuhsu1P73GColIbflAgoIB",
          },
        });

        console.log({ data });
        setStream(data.streamPath);
      } catch (error) {
        console.log(error);
      }
    }
    fetchVid();
  }, [stream]);
  return (
    <div
      className="flex justify-center w-[90%]  sm:w-[80%] md:h-[40rem]  shadow-white"
      onContextMenu={(e) => {
        e.preventDefault();
        e.stopPropagation();
        return false;
      }}>
      {ReactPlayer.canPlay(stream) ? (
        <ReactPlayer
          controls
          onError={(error) => console.log({ error })}
          fileConfig={{ attributes: { poster: podcast?.image } }}
          url={stream}
          className="rounded-md !w-full !bg-black object-fill "
        />
      ) : (
        <video
          // controls
          poster={podcast?.image}
          //   onPlaying={(e) => setIsPlaying(true)}
          // autoPlay
          preload="metadata"
          controls
          onError={(error) => console.log({ error })}
          autoPlay
          src={`${stream}`}
          // ref={videoRef}

          className="rounded-md !w-full bg-black  object-fill">
          <source src={stream}></source>
        </video>
      )}
    </div>
  );
};

export default VideoPlayer;
