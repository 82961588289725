import { Grow } from "@mui/material";
import React from "react";

import AuthModal from "../components/AuthModal";
import Content from "../components/Content";
import Features from "../components/Features";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Hero from "../components/Hero";
import LahaShow from "../components/LahaShow";
import Player from "../components/Player";
import Podcast from "../components/Podcast";
import VideoPlayer from "../components/VideoPlayer";

const Home = () => {
  return (
    <Grow in={true} timeout={2500}>
      <div className="min-h-screen">
        <Header />
        <LahaShow />

        <Content />
      </div>
    </Grow>
  );
};

export default Home;
